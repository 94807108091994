var initPagination = function() {
  var $pagination = $('#pagination');
  if ($pagination.length == 0) return;

  var $more = $pagination.find('.c-pagination__more');
  var $loader = $pagination.find('.c-pagination__loader');
  var $items = $pagination.find('.c-pagination__items');

  $items.hide();

  var $next = $items.find('.c-pagination__item--current').next();
  if ($next.length == 0) return;

  $more.css('display', 'inline-block').on('click', function(e) {
    e.preventDefault();

    $more.hide();
    $loader.css('display', 'inline-block');

    var $existing_items = $('.c-projects .c-projects__items, .c-projects .c-accordion__items');

    var url = $next.find('a').attr('href');

    $.get(url, function(data, textStatus, jqXHR) {
      var $html = $(data);

      history.pushState(
        {},
        $html.filter('title').text(),
        url + '?state=1'
      );

      // new items
      var $new_items = $html.find('.c-projects .c-projects__items, .c-projects .c-accordion__items').children();
      if ($new_items.length > 0) {
        $existing_items.append($new_items);
      }

      // pagination
      $next = $html.find('.c-pagination__items .c-pagination__item--current').next();
      if ($next.length > 0) {
        $more.css('display', 'inline-block').closest('.l-section').show();
      }

      // loader
      $loader.hide();
    });
  });
}

$(initPagination);
