(function($) {
  $(function() {
    $('.js-newsletter').on('click', function(e) {
      e.preventDefault();

      $(this).hide();
      $('.js-newsletter-form').css('display', 'flex').find('input[type="text"]').focus();
    });
  });
})(jQuery)
