$(function() {
  var isAutoPlay = true;
  function startSlickAutoplay($slick) {
    if (isAutoPlay) return;

    isAutoPlay = true;
    $slick.slick('slickSetOption', 'autoplay', true, true);
  }

  function initPlayer($player, $slick) {
    $player.YTPlayer();

    if ($slick.children().length == 1) return;

    $player.on('YTPEnd', function() {
        removeLoader($(this).closest('.c-slideshow__item'));

        $slick.slick('slickNext');
        $slick.slick('slickPlay');
      })
      .on('YTPPlay', function() {
        removeLoader($(this).closest('.c-slideshow__item'));

        startSlickAutoplay($slick);
        $slick.slick('slickPause');
      })
      .on('YTPStart', function() {
        removeLoader($(this).closest('.c-slideshow__item'));
      })
    ;
  }

  function removeLoader($parent) {
    // $parent.find('.c-preloader').remove();
    $parent.find('.c-preloader').addClass('c-preloader--hidden');
  }

  $('.c-slideshow__items').each(function() {
    var $this = $(this);

    var component = $this.data('slick-component');
    var $component = $this.closest('.c-' + component);
    var slick_class = 'c-' + component + '--slick';

    if ($component.hasClass(slick_class)) return;

    // pictures preloader
    if ($component.data('preloader')) {
      var $preloader = $($component.data('preloader'));

      var toLoadImgs = [];
      var $imgs = $this.find('img');
      $imgs.each(function() {
        var $img = $(this);
        toLoadImgs.push($img.attr('src'));

        $img.on('load', function() {
          var index = toLoadImgs.indexOf(this.src);
          if (index === -1) return;

          toLoadImgs.splice(index, 1);
          if (toLoadImgs.length == 0) {
            $preloader.addClass('c-preloader--hidden');
          }
        });
        if (this.complete) {
          $img.trigger('load');
        }
      });
    }

    // si la première slide est une vidéo
    var $initPlayer = $this.children(':eq(0)').find('.video-player');
    if ($initPlayer.length > 0) {
      isAutoPlay = false;
      initPlayer($initPlayer, $this);
    }

    // debug
    // $($component.data('preloader')).addClass('c-preloader--hidden');
    // return;

    if ($this.children().length == 1) return;

    $component.addClass(slick_class);

    var duration = parseInt($this.data('duration'));

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: isAutoPlay,
      autoplaySpeed: duration ? duration : 2000,
      infinite: true,
      pauseOnHover: false,

      dots: false,
      speed: 500,
      prevArrow: $this.prev().find('.c-slick__prev'),
      nextArrow: $this.prev().find('.c-slick__next'),
    });

    var $previousPlayer = null;

    $this.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      if ($previousPlayer && $previousPlayer.length > 0) {
        $previousPlayer.YTPPause();
      }

      var $slide = $(slick.$slides[nextSlide]);
      var $player = $slide.find('.video-player');

      if ($player.length > 0) {
        if ($slide.find('.inline-YTPlayer').length == 0) {
          // mise en pause du slideshow
          $this.slick('slickPause');

          // initialisation du player Youtube
          initPlayer($player, $this);
        }
        else {
          // lecture de la vidéo
          $player.YTPSeekTo(0);
          $player.YTPPlay();
        }
      }
      else {
        startSlickAutoplay($this);
        $this.slick('slickPlay');
      }

      $previousPlayer = $player;
    });

    // $this.on('afterChange', function(event, slick, currentSlide) {
    //   if ($previousPlayer && $previousPlayer.length > 0) {
    //     removeLoader($previousPlayer.closest('.c-slideshow__item'));
    //   }
    // });
  });
});
