(function($) {
  $(function() {
    var isDesktop = function() {
      return $('.js-filters-slide-checker').css('display') == 'none';
    }

    $window.on('popstate', function(e) {
      loadUrl(String(window.location), false);
    });

    var loadUrl = function(url, pushState) {
      var $loader = $('.c-filters__loader');
      $loader.show();

      var $existing_items = $('.c-projects .c-projects__items, .c-projects .c-accordion__items');
      $existing_items.hide().html('');
      $('#pagination').remove();

      if (pushState) {
        history.pushState(
          {},
          $html.filter('title').text(),
          url
        );
      }

      $.get(url, function(data, textStatus, jqXHR) {
        var $html = $(data);

        // items
        var $new_items = $html.find('.c-projects .c-projects__items, .c-projects .c-accordion__items').children();
        // return;
        if ($new_items.length > 0) {
          $existing_items.show().append($new_items);
        }
        else {
          //
        }

        // pagination
        if ($html.find('#pagination').length > 0) {
          $('.c-projects').after($html.find('#pagination'));
          initPagination();
        }

        // banner
        $('#banner').html($html.find('#banner'));

        // active filters
        $('.js-filters').html($html.find('.js-filters').html());
        $('.js-filters').addClass('c-filters--opened');

        // loader
        $loader.hide();
      });
    }

    $(document).on('click', '.js-filters-opener', function(e) {
      if (isDesktop()) return;

      e.preventDefault();

      var $filters = $('.js-filters');

      if ($filters.hasClass('c-filters--opened')) {
        $filters.removeClass('c-filters--opened');
        $('.js-filters-panel').slideUp(200);
      }
      else {
        $filters.addClass('c-filters--opened');
        $('.js-filters-panel').slideDown(200);
      }
    });

    $(document).on('click', '.js-filters-panel a, .js-filters-opener', function(e) {
      var $this = $(this);
      if ($this.hasClass('js-filters-opener') && !isDesktop()) return;

      e.preventDefault();

      loadUrl($this.attr('href'), true);
    });
  });
})(jQuery);
